.header-toolbar {
  z-index: 99999;

  h1 {
    @include font;
    font-size:1.3em;
    font-style: italic;
    display: inline-block;
    align-self: center;
  }

  h2 {
    color:$white;
    @include fontBd;
    font-size:1.1em;
    font-style: italic;
    align-self: center;
  }

  .lighter {
    color:rgba($white, 0.7);
    font-size: 0.8em;
    letter-spacing: -0.5px;
    @include font;
    font-style: italic;
  }

  .mat-mdc-icon-button {
    margin:0 8px;
    line-height: normal;
    border:2px solid $white;
  }
}
