html, body{
  @include font;
  font-size: $fontS;
  line-height: 1.2;
  letter-spacing: 0.02em;
  color: $black;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body{
  width: 100%;
  //overflow-x: hidden !important;
}

.section {
  max-width: 1000px;
  width: 1000px;
  margin: auto;
  position: relative;
}
