.mat-mdc-button, .mat-mdc-button-base {
  &.small {
    padding: 0 8px;
    line-height: 24px;
    --mdc-text-button-container-height: 24px;
    height: 24px;
  }
}

.mat-mdc-icon-button {
  height: 40px !important;
  width: 40px !important;
  line-height: 24px !important;
  padding: 6px !important;
}

.font-size {
  width: initial !important;
  height: initial !important;
  font-size: initial !important;
  vertical-align: bottom;

  svg {
    max-height: $fontS;
    max-width: $fontS;
    vertical-align: bottom;
  }
}

@keyframes spinner {
  to {transform: rotate(360deg);}
}

button {
  &.spinner:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%; // or -20px
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid #ffffff;
    border-top-color: transparent;
    animation: spinner .8s linear infinite;
  }

  &[color=primary] {
    &.spinner:before {
      border: 2px solid $primary;
      border-top-color: transparent;
    }
  }

  &[color=accent] {
    &.spinner:before {
      border: 2px solid $accent;
      border-top-color: transparent;
    }
  }
}
