/* --- COLORS --- */
$primary: #00828c;
$accent: #CA0B39;//#ee032b; // old (System U red) : #e71b34
$third: #2480b0;
$black: #333333;
$light-grey: #f2f2f2;
$middle-grey: #a7a7a7;
$dark-grey: #686868;
$white: #ffffff;
$red: #c51609;
$orange: #FF8F00;
$green: #6a9d34;
$pink: #d079a9;
