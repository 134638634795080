@use "sass:math";

// Media queries breakpoints
// --------------------------------------------------

// Extra small screen / phone
// Note: Deprecated $screen-xs and $screen-phone as of v3.0.1
$screen-xs: 480px !default;
$screen-xs-min: $screen-xs !default;
$screen-phone: $screen-xs-min !default;

// Small screen / tablet
// Note: Deprecated $screen-sm and $screen-tablet as of v3.0.1
$screen-sm: 768px !default;
$screen-sm-min: $screen-sm !default;
$screen-tablet: $screen-sm-min !default;

// Medium screen / desktop
// Note: Deprecated $screen-md and $screen-desktop as of v3.0.1
$screen-md: 992px !default;
$screen-md-min: $screen-md !default;
$screen-desktop: $screen-md-min !default;

// Large screen / wide desktop
// Note: Deprecated $screen-lg and $screen-lg-desktop as of v3.0.1
$screen-lg: 1200px !default;
$screen-lg-min: $screen-lg !default;
$screen-lg-desktop: $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1) !default;
$screen-sm-max: ($screen-md-min - 1) !default;
$screen-md-max: ($screen-lg-min - 1) !default;


// Grid system
// --------------------------------------------------

// Number of columns in the grid system
$grid-columns: 12 !default;

// Padding, to be divided by two and applied to the left and right of all columns
$grid-gutter-width: 24px !default;
$grid-gutter-width-xs: math.div($grid-gutter-width, 2) !default;
$gutterWidth: $grid-gutter-width;
$vSpace: $grid-gutter-width;

// Point at which the navbar stops collapsing
$grid-float-breakpoint: $screen-sm-min !default;



// Container sizes
// --------------------------------------------------

// Small screen / tablet
$container-tablet: (720px + $grid-gutter-width) !default;
$container-sm: $container-tablet !default;

// Medium screen / desktop
$container-desktop: (940px + $grid-gutter-width)!important !default;
$container-md: $container-desktop !default;

// Large screen / wide desktop
$container-large-desktop: (1140px + $grid-gutter-width)!important !default;
$container-lg: $container-large-desktop !default;


// Typos sizes
$fontXXS: 8px;
$fontXS: 12px;
$fontS: 14px;
$fontM: 17px;
$fontL: 28px;
$fontXL: 48px;
$fontXXL: 76px;
