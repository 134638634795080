@import "variables";

h1{
  margin:1em 0;
  @include fontBd;
  font-size: $fontXL;
  line-height: $fontXL;
  letter-spacing: normal;
}

h2{
  margin:1em 0;
  @include fontBd;
  font-size: $fontM;
  line-height: $fontM;
  letter-spacing: normal;
  color:$primary;
}

h3{
  margin:1em 0;
  @include fontBd;
  font-size: $fontM;
  line-height: $fontM;
  letter-spacing: normal;
}

h4, h5{
  padding-bottom: 0.5em;
}

p {
  font-size: 1em;
  margin:0 0 1em 0;
}

ul {
  margin:0 0 1em 1em;
  li {
    font-size: 1em;
    &:before {
      content: "-";
      position: absolute;
      margin-left: -1em;
    }
  }
}

a {
  text-decoration: none;
  color:$primary;

  &:hover {
    text-decoration: underline;
  }
}

.link {
  cursor: pointer;
}

strong,
b {
  @include font;
  font-weight: 700;
}

.small{
  font-size: $fontXS;
}

.medium {
  font-size: $fontM;
}

.big {
  font-size: $fontL;
}

.error {
  color:$red;
}

.success {
  color:$green;
}

.primary {
  color: $primary;
}

.accent {
  color: $accent;
}

// TEXTS HIGHLIGHT
::-moz-selection{background: darken($primary, 10%); color:$white;}
::-webkit-selection{background: darken($primary, 10%); color:$white;}
::selection{background: darken($primary, 10%); color:$white;}
