@import "colors";

.mat-mdc-dialog-actions {
  display: block !important;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 99999;
}

.mat-mdc-snack-bar-container.error-snackbar {
  background: $red;
  font-weight: bold;
}

.mat-mdc-snack-bar-container.success-snackbar {
  background: $green;
  font-weight: bold;
}

/*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-tab-label {
  //text-transform: uppercase;
}

/*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-tab-label-active {
  background-color: $primary;
  color: $white;
}

html {
  --mdc-checkbox-state-layer-size: 25px;
}

//.mdc-form-field>label {
//  padding-left: 1px !important;
//}
.mat-mdc-checkbox-ripple, .mat-mdc-checkbox, .mdc-checkbox__ripple {
  top: -5px !important;
  bottom: -5px !important;
  left: -5px !important;
  right: -5px !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-tab-body-content {
  padding: 10px;
}

.mat-mdc-tab-header .mat-mdc-tab {
  flex-grow: 0 !important;
}

.mdc-text-field {
  padding: 0 !important;
}

.mat-mdc-form-field {
  font-family: 'Roboto', sans-serif !important;
}

.mat-mdc-form-field-focus-overlay {
  background-color: transparent;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent;
}
